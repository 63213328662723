<template>
  <v-card flat>
    <v-card-title>Plans</v-card-title>
    <v-card-text>
      <v-toolbar flat>
        <v-text-field
            label="search"
            v-model="search"
            clearable
        />
        <v-spacer/>
        <v-btn outlined @click="addNewPlan">New Plan</v-btn>
      </v-toolbar>
      <v-data-table
          :search="search"
          :items="planList"
          :headers="headers"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item.action="{item:{id}}">
          <v-btn outlined small @click="$router.push({name:'PlanEdit', params:{id}})">Edit</v-btn>
        </template>
        <template v-slot:item.ranges="{item : { ranges}}">
          <div v-for="(r,i) of (
            ranges &&
            ranges
              .filter(r => !!r)
              .map(r => `${r.price} : ${r.description}` + (r.free_shipping ? '(free shipping)' : ''))
              )" v-bind:key="i">
            {{ r }}
          </div>
        </template>
        <template v-slot:item.adjust="{item : { adjust}}">
          {{ adjust }}
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";


export default {
  name: "Plans",
  mounted() {
    return this.fetchPlans();
  },
  methods: {
    ...mapActions(['fetchPlans', 'updatePlan']),
    addNewPlan() {
      let planId = prompt('Enter the id of the new plan: (no spaces, lower case)') || '';
      planId = planId.toLowerCase().replaceAll(/[\W\s]/g, '');
      if (planId) {
        if (this.plans[planId]) {
          alert('a plan already exists with the plan id ' + planId);
        } else {
          return this
              .updatePlan({id: planId, update: {}})
              .then(() => this.fetchPlans({force: true}))
              .then(() => this.$router.push({name: 'PlanEdit', params: {id: planId}}));
        }
      }
    }
  },
  computed: {
    ...mapState(['plans']),
    planList() {
      return Object.entries(this.plans)
          .map(([k, v]) => ({...v, id: k}))
          .sort((a, b) => a.id.localeCompare(b.id))
    }
  },
  data() {
    return {
      headers:
          'id, stream, name, per_meal, large_price, alacarte, ranges, action' // adjust,
              .split(', ')
              .map(s => ({text: s, value: s})),
      search: null,


    }
  }
}
</script>

<style scoped>

</style>