import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Plans")]),_c(VCardText,[_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"label":"search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":_vm.addNewPlan}},[_vm._v("New Plan")])],1),_c(VDataTable,{attrs:{"search":_vm.search,"items":_vm.planList,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var id = ref.item.id;
return [_c(VBtn,{attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.$router.push({name:'PlanEdit', params:{id: id}})}}},[_vm._v("Edit")])]}},{key:"item.ranges",fn:function(ref){
var ranges = ref.item.ranges;
return _vm._l(((
          ranges &&
          ranges
            .filter(function (r) { return !!r; })
            .map(function (r) { return (r.price) + " : " + (r.description) + (r.free_shipping ? '(free shipping)' : ''); })
            )),function(r,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(r)+" ")])})}},{key:"item.adjust",fn:function(ref){
            var adjust = ref.item.adjust;
return [_vm._v(" "+_vm._s(adjust)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }